import React, { Component } from 'react';

class Footer extends Component {
  render() {

    if(this.props.data){
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
      <footer>

     <div className="row">
        <div className="twelve columns">
        

           <ul className="copyright">
              <li>&copy; Copyright 2022 - Max Angelo Dapitilla Perin, BSCpE, MSCS, CCpE (ᜋᜃᜐ | Makasa)</li>
              <li>References: </li>
              <li>Max Angelo Dapitilla Perin (Scopus) <a target="_blank" title="Scopus" href="https://www.scopus.com/authid/detail.uri?authorId=57416396400">https://www.scopus.com/authid/detail.uri?authorId=57416396400</a> </li>
              <li>Max Angelo Dapitilla Perin (LinkedIn) <a target="_blank" title="LinkedIn" href="https://www.linkedin.com/in/maxangelo987/">https://www.linkedin.com/in/maxangelo987/</a> </li>
              <li>Tbakerx. Tbakerx/react-resume-template: A personal resume website template built with react.js, typescript, next.js, and styled with Tailwind CSS. (Version 1.0) From <a target="_blank" title="tbakerx/react-resume-template" href="https://github.com/tbakerx/react-resume-template">https://github.com/tbakerx/react-resume-template </a></li>
              <li>Develop using <a title="React.js" href="https://reactjs.org/">React.js</a>, Last Update: August 1, 2023</li>
           </ul>

        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
     </div>
  </footer>
    );
  }
}

/*    <ul className="social-links">
              {networks}
           </ul>  */
export default Footer;
