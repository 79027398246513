import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if(this.props.data){
      var skillmessage = this.props.data.skillmessage;
      var skills_0_title = this.props.data.skills_0_title;
      var skills_1_title = this.props.data.skills_1_title;
      var skills_2_title = this.props.data.skills_2_title;
      var skills_3_title = this.props.data.skills_3_title;
      var skills_4_title = this.props.data.skills_4_title;
      var skills_5_title = this.props.data.skills_5_title;
      var skills_6_title = this.props.data.skills_6_title;
      var skills_7_title = this.props.data.skills_7_title;
      var skills_8_title = this.props.data.skills_8_title;
      var skills_9_title = this.props.data.skills_9_title;
      var skills_10_title = this.props.data.skills_10_title;
      var skills_11_title = this.props.data.skills_11_title;
      var skills_12_title = this.props.data.skills_12_title;
      var education = this.props.data.education.map(function(education){
        return <div key={education.school_fil}><h3>{education.school_fil} <br/> {education.school_eng}</h3>
        <p className="info">{education.degree_fil}<br/>{education.degree_eng} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var certificate_courses_and_schools = this.props.data.certificate_courses_and_schools.map(function(certificate_courses_and_schools){
        return <div key={certificate_courses_and_schools.company}><h3>{certificate_courses_and_schools.company}</h3>
            <p className="info">{certificate_courses_and_schools.title}<span>&bull;</span> <em className="date">{certificate_courses_and_schools.years}</em></p>
            <p>{certificate_courses_and_schools.description}</p>
        </div>
      })
    
      var publications = this.props.data.publications.map(function(publications){
        return <div key={publications.title}><h3>{publications.title}</h3>
            <p className="info">{publications.publisher}<span>&bull;</span> <em className="date">{publications.date}</em></p>
            <p>{publications.description}</p>
        </div>
      })
      var skills_0 = this.props.data.skills_0.map(function(skills_0){
        var className = 'bar-expand '+skills_0.name.toLowerCase();
        return <li key={skills_0.name}><span style={{width:skills_0.level}}className={className}></span><em>{skills_0.name}</em></li>
      })
      var skills_1 = this.props.data.skills_1.map(function(skills_1){
        var className = 'bar-expand '+skills_1.name.toLowerCase();
        return <li key={skills_1.name}><span style={{width:skills_1.level}}className={className}></span><em>{skills_1.name}</em></li>
      })
      var skills_2 = this.props.data.skills_2.map(function(skills_2){
        var className = 'bar-expand '+skills_2.name.toLowerCase();
        return <li key={skills_2.name}><span style={{width:skills_2.level}}className={className}></span><em>{skills_2.name}</em></li>
      })
      var skills_3 = this.props.data.skills_3.map(function(skills_3){
        var className = 'bar-expand '+skills_3.name.toLowerCase();
        return <li key={skills_3.name}><span style={{width:skills_3.level}}className={className}></span><em>{skills_3.name}</em></li>
      })
      var skills_4 = this.props.data.skills_4.map(function(skills_4){
        var className = 'bar-expand '+skills_4.name.toLowerCase();
        return <li key={skills_4.name}><span style={{width:skills_4.level}}className={className}></span><em>{skills_4.name}</em></li>
      })
      var skills_5 = this.props.data.skills_5.map(function(skills_5){
        var className = 'bar-expand '+skills_5.name.toLowerCase();
        return <li key={skills_5.name}><span style={{width:skills_5.level}}className={className}></span><em>{skills_5.name}</em></li>
      })
      var skills_6 = this.props.data.skills_6.map(function(skills_6){
        var className = 'bar-expand '+skills_6.name.toLowerCase();
        return <li key={skills_6.name}><span style={{width:skills_6.level}}className={className}></span><em>{skills_6.name}</em></li>
      })
      var skills_7 = this.props.data.skills_7.map(function(skills_7){
        var className = 'bar-expand '+skills_7.name.toLowerCase();
        return <li key={skills_7.name}><span style={{width:skills_7.level}}className={className}></span><em>{skills_7.name}</em></li>
      })
      var skills_8 = this.props.data.skills_8.map(function(skills_8){
        var className = 'bar-expand '+skills_8.name.toLowerCase();
        return <li key={skills_8.name}><span style={{width:skills_8.level}}className={className}></span><em>{skills_8.name}</em></li>
      })
      var skills_9 = this.props.data.skills_9.map(function(skills_9){
        var className = 'bar-expand '+skills_9.name.toLowerCase();
        return <li key={skills_9.name}><span style={{width:skills_9.level}}className={className}></span><em>{skills_9.name}</em></li>
      })
      var skills_10 = this.props.data.skills_10.map(function(skills_10){
        var className = 'bar-expand '+skills_10.name.toLowerCase();
        return <li key={skills_10.name}><span style={{width:skills_10.level}}className={className}></span><em>{skills_10.name}</em></li>
      })
      var skills_11 = this.props.data.skills_11.map(function(skills_11){
        var className = 'bar-expand '+skills_11.name.toLowerCase();
        return <li key={skills_11.name}><span style={{width:skills_11.level}}className={className}></span><em>{skills_11.name}</em></li>
      })
      var skills_12 = this.props.data.skills_12.map(function(skills_12){
        var className = 'bar-expand '+skills_12.name.toLowerCase();
        return <li key={skills_12.name}><span style={{width:skills_12.level}}className={className}></span><em>{skills_12.name}</em></li>
      })
      var work = this.props.data.work.map(function(work){
        // Replace newline characters with <br> tags
        var titleWithBreaks = work.title.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));
        
        return (
          <div key={work.company}>
            <h3>{work.company}</h3>
            <p className="info">
              {titleWithBreaks}<span>&bull;</span>
              <em className="date">{work.years}</em>
            </p>
            <p>{work.description}</p>
          </div>
        );
      });
    }

    return (
      <section id="resume">

      <div className="row education">
         <div className="three columns header-col">
            <h2><span>Educational Attainment</span></h2>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {education}
               </div>
            </div>
         </div>


         <div className="three columns header-col">
            <h2><span>Certificate Courses and Schools</span></h2>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {certificate_courses_and_schools}
               </div>
            </div>
         </div>

      </div>

 

      <div className="row work">

         <div className="three columns header-col">
            <h2><span>Work and Employment</span></h2>
         </div>

         <div className="nine columns main-col">
          {work}
        </div>
    </div>

    <div className="row publication">

      <div className="three columns header-col">
        <h2><span>Publications (Scopus)</span></h2>
      </div>

      <div className="nine columns main-col">
      {publications}
      </div>
      </div>


      <div className="row skill">

         <div className="three columns header-col">
            <h2><span>Skills</span></h2>
         </div>

         <div className="nine columns main-col">
            <br></br>
            <p>{skillmessage}
            </p>
            
            <h2>{skills_0_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_0}
				</ul>
			</div>

            <h2>{skills_1_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_1}
				</ul>
			</div>

            <h2>{skills_2_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_2}
				</ul>
			</div>

            <h2>{skills_3_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_3}
				</ul>
			</div>

            <h2>{skills_4_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_4}
				</ul>
			</div>

            <h2>{skills_5_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_5}
				</ul>
			</div>
            
            <h2>{skills_6_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_6}
				</ul>
			</div>
            
            <h2>{skills_7_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_7}
				</ul>
			</div>

            <h2>{skills_8_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_8}
				</ul>
			</div>

            <h2>{skills_9_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_9}
				</ul>
			</div>

            <h2>{skills_12_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_12}
				</ul>
			</div>
            
            <h2>{skills_10_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_10}
				</ul>
			</div>

            <h2>{skills_11_title}</h2>
			<div className="bars">
				<ul className="skills">
					{skills_11}
				</ul>
			</div>

		</div>
      </div>
   </section>
    );
  }
}

export default Resume;
